import { SidebarRoute } from "@components/layout/SidebarRouter";
import React from "react";
import Customer from "src/models/Customer";
import ModelGrid from './ModelGrid';

export default class CustomerGrid extends ModelGrid<Customer> {

  public formRoute = SidebarRoute.CustomerForm
  static gridName = "customer"

  title() {
    new Customer({})
    return "Chantiers"
  }

  onRowClick = null;
  gridConfig = {
    headers: ["Nom", "Email", "Téléphone", "Adresse"],
    body: [
      (customer: Customer) => <b>{customer.name}</b>,
      (customer: Customer) => <div>{customer.email}</div>,
      (customer: Customer) => customer.phone,
      (customer: Customer) => <div>
      <div>{customer.address}</div>
      <div>{customer.cpAndCity()}</div>
    </div>,
      
    ]
  }

  multipleActions = [
    {
      action: () => this.props.manager.deleteMany(this.props.selected),
      label: "Supprimer les chantiers"
    }
  ];

  onDelete = null
}