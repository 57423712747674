import { sessionBloc } from "@bloc/SessionBloc";
import SearchInput from "@components/input/SearchInput";
import { SidebarRoute } from "@components/layout/SidebarRouter";
import EntityManager from "@services/EntityManager";
import classNames from "classnames";
import dayjs from "dayjs";
import React from "react";
import { ProgressBar } from "react-bootstrap";
import Yard from "src/models/Yard";
import DOMService from 'src/services/DOMService';
import ModelGrid from './ModelGrid';

export default class YardGrid extends ModelGrid<Yard> {

  public formRoute = SidebarRoute.YardForm
  static gridName = "yard"

  title() {
    return "Chantiers"
  }

  gridConfig = {
    headers: ["Nom", "Secteur", "Client", "Numéro de lot", "Début", "Fin", "Etat"],
    rowClassName: (model: Yard) => model.deletedAt ? "bg-light-warning" : null,
    body: [
      (yard: Yard) => <b className="text-capitalize">{yard.name}</b>,
      (yard: Yard) => <div className="text-capitalize">{yard.city}</div>,
      (yard: Yard) => yard.customer?.name,
      (yard: Yard) => yard.batch,
      (yard: Yard) => dayjs(yard.startAt).format("D MMMM YYYY"),
      (yard: Yard) => dayjs(yard.endAt).format("D MMMM YYYY"),
      (yard: Yard) => this.renderStatus(yard),
    ]
  }

  onRowClick = (model: Yard) => {
    sessionBloc.push(model.url())
  };

  onEdit = sessionBloc.isManager() ? this.onEdit : null
  onClickNew = sessionBloc.isManager() ? this.onClickNew : null

  public rowActions = (model: Yard) => !model.deletedAt && <div className="small-btn-icon me-2" onClick={() => this.onArchive(model)}>
      <i className="fas fa-archive"></i>
    </div>

  onArchive = (model) => {
    DOMService.alert({
      title: "Archiver",
      message: "Voulez vous vraiment archiver ce chantier ?",
      onConfirm: async () => {
        let response = await EntityManager.delete(model);
        if (response.ok) {
          DOMService.closeModal();
          this.props.manager.loadModels();
        } else {
          DOMService.modal(<div className="p-4 text-center text-danger">
            <h4 className="fw-bold">Erreur</h4>
            <div>{response.errorMessage}</div>
          </div>)
        }
      }
    });
  }

  multipleActions = [
    {
      action: () => this.props.manager.deleteMany(this.props.selected),
      label: "Supprimer les chantiers"
    }
  ];

  renderStatus = (yard) => {
    return !yard.closed ? <div className="row-flex">
    <ProgressBar className="min-width-8" now={yard.progress * 100}/>
    <div className="text-s ms-2">{yard.getProgressText()}</div>
  </div> : <div className={"badge bg-info"}>Cloturé</div>
  }

  onDelete = null

  firstBar() {
    const { search, manager } = this.props;
    const active = manager.state.filter?.hasOwnProperty("scope") && manager.state.filter["scope"] === "withTrashed"
    return <div className="mb-3">
      <div className="row-between">
        <div className="row-flex">
          <div className="me-3">
            <SearchInput value={ search } innerRef={ manager.inputRef } onChanged={ manager.handleSearch } onClear={ manager.clearSearch } onSearch={ manager.onSearch } placeholder={"Rechercher"} />
          </div>
          <div>
            <div key={"withTrashed"} className={classNames({"text-white": active, "btn w-100 btn-light text-secondary text-s rounded-2": true, "bg-warning": active})} onClick={() => active ? manager.clearFilter() : manager.mergeFilter({scope: "withTrashed"})} >Avec archivé</div>
          </div>
        </div>
        <div className="row-end">
          {this.specificActions()}
          {this.onClickNew && <button onClick={this.onClickNew} className="btn btn-primary">Nouveau</button>}
        </div>
      </div>
    </div>
  }
  
}