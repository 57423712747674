const env = process.env.NODE_ENV

const forceProd = false;

export interface EnvConfig {
    protocol: string
    frontUrl: string
    apiUrl: string
    nodeUrl: string
}

const envConfig = {
  development: {
    protocol: 'http://',
    frontUrl: 'localhost:3000',
    apiUrl: 'localhost:8000/',
  },
  production: {
    protocol: 'https://',
    frontUrl: 'godin.urioz.com',
    apiUrl: 'apigodin.urioz.com/',
  }
}

export const getFrontUrl = () => envConfig[forceProd ? "production" : env].protocol + envConfig[forceProd ? "production" : env].frontUrl;

let configByEnv: EnvConfig = envConfig[forceProd ? "production" : env]

export default configByEnv
