import Attribute from './core/Attribute';
import AttributesCollection from './core/AttributeCollection';
import FileAttribute from './core/FileAttribute';
import uniqueId from 'lodash/uniqueId';


export default class Model {

  public id: any;
  public modelName: string;
  static modelUrl: string;
  public createdAt: Date;
  public updatedAt: Date;

  public attributes: AttributesCollection;

  public constructor(json: any) {
    this.id          = json?.id;
    this.createdAt   = json.createdAt && new Date(json.createdAt);
    this.updatedAt   = json.updatedAt && new Date(json.updatedAt);
  }

  public setOptionAttributesToDefault() {
    this.attributes.setDefaultValues();
  }

  protected fillAttributes(json) {
    this.attributes.fillWithJson(this, json)
  }

  public getBackendModelType(): string {
    return `App\\Models\\${this.modelName.charAt(0).toUpperCase() + this.modelName.slice(1)}`;
  }

  public getFrontUrl(): string {
    let classs: any = this.constructor;
    return `/${classs.modelUrl}/${this.id}`;
  }

  public getElementId() {
    return this.modelName + (this.id || uniqueId());
  }

  public getApiParam(options?: {only?: string[], dirty?: boolean}) {
    return this.attributes.getApiParam(this, options);
  }

  public update(updateFields) {
    Object.keys(updateFields).forEach(key => {
      this[key] = updateFields[key];
    })
  }

  public serialize() {
    let serialized = {};
    Object.keys(this.attributes.attributes).forEach((key) => serialized[key] = this.attributes.attributes[key]?.serialize());
    return {id: this.id, ...serialized};
  }

  public onChange(callback) {
    this.attributes.listenAllChanges(callback);
  }

  public getAttribute(name: string): Attribute {
    return this.attributes.attributes[name];
  }

  public hasFileAttribute() {
    return Object.values(this.attributes.attributes).find(a => a instanceof FileAttribute) !== undefined;
  }

  public checkForSubmit() {
    return this.attributes.checkForSubmit();
  }

  public originalOf(attr: string) {
    return this.getAttribute(attr)?.originalValue;
  }

  public with(attr: string, value: any) {
    this[attr] = value;
    return this;
  }

}

