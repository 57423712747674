import AsyncButton from '@components/button/AsyncButton';
import SmartDateRangePicker from '@components/input/SmartDateRangePicker';
import SidebarLayout from '@components/layout/SidebarLayout';
import formModel, { IInjectedFormModelProps } from '@components/logic/FormModel';
import UserSelect from '@components/modelSelect/UserSelect';
import Slot from '@models/Slot';
import groupBy from 'lodash/groupBy';
import * as React from 'react';

export interface ISlotYardModalProps {
  slots: Slot[]
}

class SlotForm extends React.Component<ISlotYardModalProps & IInjectedFormModelProps<Slot>> {

  checkAvailable = (models) => {
    
    let byUser = groupBy(this.props.slots, "userId");
    return models.filter(model => {
      let slots = byUser[model.id];
      if (!slots) return true;
      
      return slots.filter(s => !s.dontOverlap(this.props.model.startAt, this.props.model.endAt)).length === 0;
    })
  }

  public render() {
    const {model, submitting, submit} = this.props;
    return (
      <SidebarLayout
        title={model.id ? "Modification d'un créneau" : "Assigner un ouvrier au chantier"}
        bottomArea={<AsyncButton className="btn btn-primary w-100 btn-lg" loading={submitting} onClick={submit} >{model.id ? "Modifier le créneau" : "Créer le créneau"}</AsyncButton>}
      >
        <div className="fadeInDown">
          <SmartDateRangePicker model={model} names={["startAt", "endAt"]} label="Période" containerClassName="mb-3"/>
          <UserSelect filterModels={this.checkAvailable} model={model} name="user" placeholder="Choisir un ouvrier" label="Ouvrier" containerClassName="mb-3"  params={{scope: "onlyEmployee"}}/>
        </div>
      </SidebarLayout>
    );
  }
}

export default formModel<IInjectedFormModelProps<Slot>>(SlotForm, {modelClass: Slot})