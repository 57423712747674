import TextIcon from '@components/common/TextIcon';
import UserSelect from '@components/modelSelect/UserSelect';
import * as React from 'react';
import Model from '../../models/Model';
import User from '../../models/User';

export interface IProductLabelRowFormProps {
  model: Model
  attribute: string
  submodelClass: any
}

export interface IProductLabelRowFormState {
}

export default class ProductLabelRowForm extends React.Component<IProductLabelRowFormProps, IProductLabelRowFormState> {

  constructor(props: IProductLabelRowFormProps) {
    super(props);

    this.state = {
    }
  }

  delete(option) {
    const {model, attribute} = this.props;
    model[attribute] = model[attribute].filter(o => o.id !== option.id);
    this.setState({})
  }

  onNewRowChange(option) {
    const {model, attribute} = this.props;
    model[attribute].push(option);
    this.setState({})
  }

  public render() {
    const {model, attribute} = this.props;
    return <div>
      <div className="row-flex mb-3">
        <UserSelect filterModels={(models) => models.filter(m => !model[attribute].find(u => u.id === m.id))} onChange={(option) => this.onNewRowChange(option)} placeholder="Ajouter un responsable" params={{scope: "onlyResponsibles"}}/>
      </div>
      <div className="">
        {model[attribute].map((user: User) => (
          <div key={user.getElementId()} className="yard-user-deletable mb-2 me-2">
            <div className="row-flex">
              <div className="mx-3">
                <TextIcon leftIcon="fas fa-user">{user.getFullName()}</TextIcon>
              </div>
              <div onClick={() => this.delete(user)} className="px-3 clear-btn ms-auto pointer me-n2"><i className="text-secondary far fa-times"></i></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  }
}
