
import TextIcon from '@components/common/TextIcon';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import AdminRouter from '../../AdminRouter';
import { consumeSessionBloc, ISessionBlocConsumer, sessionBloc } from '../../bloc/SessionBloc';
import logo from "../../assets/images/favicon.png"
import classNames from 'classnames';

export interface INavbarProps {
  transparent?: boolean
  shadow?: boolean
  onMinimize?
  minimize?: boolean
}

export interface INavbarState {
}

function Navbar(props: ISessionBlocConsumer & INavbarProps) {

  return (<>
    <nav className="navbar d-none d-md-block">
      <div className="row-center head-up">
        <div className="rounded row-flex sidebar-link">
          <img src={logo} alt="" width="30px" />
        </div>
        <div className="fw-bold ms-1 godin-logo mb-0">GODIN</div>
      </div>
      <div className="w-100 overflow-hidden">
        {AdminRouter(sessionBloc.user).map(route => (
          <NavLink className="mb-3" key={route.url} to={route.url} exact={route.exact}>
            <div className={"px-3 py-2 rounded row-flex sidebar-link"}>
              <TextIcon spacing={3} leftIcon={"fas " + route.icon} >{!props.minimize ? route.label : null}</TextIcon>
            </div>
          </NavLink>
        ))}
      </div>
      <div style={{height: "430px"}}>
        <div className={classNames({"d-flex flex-column h-100 justify-content-end": true, "align-items-center": props.minimize, "align-items-end": !props.minimize})}>
          <i onClick={props.onMinimize} className={classNames({"fas fa-chevron-left pointer p-2 text-secondary": true, "fa-rotate-180": props.minimize})}></i>
        </div>
      </div>
    </nav>
    <nav className="mobile-navbar row-end d-md-none">
      {AdminRouter(sessionBloc.user).map(route => (
        <NavLink className="" key={route.url} to={route.url} exact={route.exact}>
          <div className="mobile-navbar-btn">
            <i className={"fas " + route.icon}></i>
          </div>
        </NavLink>
      ))}
    </nav>
    </>
  );
}

export default consumeSessionBloc(React.memo(Navbar, (prevProps, nextProps) => prevProps.logged === nextProps.logged && prevProps.minimize === nextProps.minimize));
