import classNames from 'classnames';
import * as React from 'react';

export interface scopeConfig {
  value: any
  name: string
  label: string
}

export interface IScoperProps {
  scopes: scopeConfig[]
  manager: any
}

export default function Scoper ({scopes, manager}: IScoperProps) {

  const isAll = () => {
    let names = allFilterKeys()
    return names.filter(n => !Object.keys(manager.state.filter).includes(n)).length === names.length
  }

  const allFilterKeys = () => {
    let names = []
    scopes.forEach((scope) => {
      if (!names.includes(scope.name)) names.push(scope.name);
    });
    return names;
  }

  const clearFilter = () => {
    let names = allFilterKeys();
    manager.removeFilters(names)
  }

  return (
    <>
      <ScopeButton active={isAll()} onClick={clearFilter} >Tous</ScopeButton>
      { scopes.map(scope => (
        <ScopeButton key={scope.value} active={manager.state.filter?.hasOwnProperty(scope.name) && manager.state.filter[scope.name] === scope.value} onClick={() => manager.mergeFilter({[scope.name]: scope.value})} >{scope.label}</ScopeButton>
      ))
      }
    </>
  );
}

export function ScopeButton({onClick, active, children, backgroundColor = null}) {
  return <div style={active ? {backgroundColor} : {}} className={classNames({"text-white": active, "btn w-100 btn-light text-secondary text-s rounded-2": true, "bg-primary": !backgroundColor && active})} onClick={onClick}>{children}</div>
}