import Model from '../Model';
import Attribute from './Attribute';
import { OptionAttribute } from './OptionAttribute';

export default class AttributesCollection {

  public attributes: {[name: string]: Attribute}

  constructor(attributes: {[name: string]: Attribute}) {
    this.attributes = attributes;
  }

  public fillWithJson(model, json): void {
    Object.values(this.attributes).forEach(attribute => attribute.fill(model, json));
  }

  public getSubmittables(): Attribute[] {
    return Object.values(this.attributes).filter(a => a.submittable);
  }

  private getSubmittableAttributes(only?: string[]) {
    let submittables = this.getSubmittables();
    if (only) return submittables.filter(a => only.includes(a.name))
    return submittables;
  }

  public setDefaultValues() {
    let optionAttributes = Object.values(this.attributes).filter(a => a instanceof OptionAttribute);
    optionAttributes.forEach((option: OptionAttribute) => option.setDefault());
  }

  public getDirtyAttributes(only) {
    let submittables = this.getSubmittableAttributes(only);
    return submittables.filter(a => a.isDirty());
  }

  public toMapApi(model, options: any = {}): any {
    let submittable;
    if (options.force)      submittable = Object.values(this.attributes)
    else if (options.dirty) submittable = this.getDirtyAttributes(options.only);
    else                    submittable = this.getSubmittableAttributes(options.only);
    let data = {id: model.id};
    submittable.forEach(attribute => {
      data[attribute.key] = attribute.toApiData(model);
    })
    return data;
  }

  private toFormDataApi(model, params): FormData {
    let formData = new FormData();
    let attr = [];
    this.indexSerialize(params, attr, model.modelName, true)
    Object.keys(attr).forEach((key) => {
      formData.append(key, attr[key]);
    })
    return formData;
  }

  public indexSerialize(object, array, baseKey, base = false) {
    Object.keys(object).forEach(key => {
      let formKey = base ? key : baseKey + "[" + key + "]";
      let formValue = object[key]
      if (Array.isArray(formValue) || (formValue instanceof Object && formValue.constructor.name !== "File")) {
        this.indexSerialize(formValue, array, formKey)
      } else {
        if (formValue !== undefined) array[formKey] = formValue;
      }
    })
  }

  public getApiParam(model: Model, options?: {only?: string[], dirty?: boolean}) {
    let params;
    params = this.toMapApi(model, options);
    if (this.hasFileAttribute(options?.only)) params = this.toFormDataApi(model, params);
    return params;
  }

  public getDirtyParam(model: Model, only?: string[]) {
    let params;
    params = this.toMapApi(model, {only});
    if (this.hasFileAttribute(only)) params = this.toFormDataApi(model, params);
    return params;
  }

  public hasFileAttribute(only?) {
    let arrayAttribute = Object.values(this.getSubmittables());
    let has = false;
    if (only) arrayAttribute = arrayAttribute.filter(a => only.includes(a.name));
    arrayAttribute.forEach(attr => {
      if (attr.hasFileAttribute()) has = true;
    });
    return has;
  }

  public listenAllChanges(callback) {
    Object.values(this.attributes).forEach(a => a.listen(callback));
  }


  public checkForSubmit(): boolean {
    let errors = 0;
    this.getSubmittables().forEach(attr => {
      if (!attr.checkForSubmit() ) console.log(attr);
      
      errors += attr.checkForSubmit() ? 0 : 1
    })
    return errors === 0;
  }

  setServerErrors(errors: any[]) {
    errors.forEach((message, field) => {
      if (this.attributes[field]) {
        let attr = this.attributes[field];
        attr.errors = {message}
        attr.change();
      }
    });
  }

  public isValid() {
    let valid = true;
    return valid;
  }
}