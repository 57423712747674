import * as React from 'react';
import Model from '../../models/Model';
import RecordManagerV2, { IRecordManagerV2Options, IRecordManagerV2State } from '../logic/RecordManagerV2';

export interface IAdminModelsListProps {
  gridClass: any
  options: IRecordManagerV2Options
}

export default function AdminModelsList(props: IAdminModelsListProps) {

  const Grid = props.gridClass
  let defaultState: IRecordManagerV2State<Model> = {}
  if (Grid.select) defaultState = {filter: {select: Grid.select}}
  const perPage = localStorage.getItem(`gridPerPage${Grid.gridName}`);
  if (perPage) defaultState.perPage = parseInt(perPage)
  return <RecordManagerV2 options={props.options} defaultState={defaultState}>
    {(manager, props) => <div className="">
      <Grid
        manager={manager}
        {...props}
      />
    </div>}
  </RecordManagerV2>
}