import UserCalendar from '@components/calendar/UserCalendar';
import SmallLoading from '@components/common/SmallLoading';
import TextIcon from '@components/common/TextIcon';
import MainLayout from '@components/layout/MainLayout';
import User from '@models/User';
import ChartService from '@services/ChartService';
import * as React from 'react';
import { Line } from 'react-chartjs-2';
import showModel, { IInjectedShowModelProps, IShowModelProps } from '../components/logic/ShowModel';
import ApiService from '@services/ApiService';
import OptionService from '@services/OptionService';
import { ScopeButton } from '@components/common/Scoper';
import { currentDayRange, currentWeekRange, currentMonthRange } from 'src/helper/DateHelper';
import DOMService from '@services/DOMService';
import { SidebarRoute } from '@components/layout/SidebarRouter';

export interface IUserShowPageProps extends IInjectedShowModelProps<User> {
  id
}

class UserShowPage extends React.Component<IShowModelProps<User>, any> {

  periodSelects = [
    {label: "Total", value: [null, null]},
    {label: "Ce mois-ci", value: currentMonthRange()},
    {label: "Cette semaine", value: currentWeekRange()},
    {label: "Ce jour", value: currentDayRange()}
  ]

  constructor(props) {
    super(props)

    this.state = {
      donePrestations: null,
      evolution: null,
      selectedPeriod: this.periodSelects[0].value
    }
  }

  componentDidMount() {
    this.loadStat()
  }

  handleChangePeriod(selectedPeriod) {
    this.setState({selectedPeriod}, () => this.loadStat());
  }

  async loadStat() {
    let data = (await ApiService.get("dashboard/performed", {userId: this.props.id, start: this.state.selectedPeriod[0]?.toJSON(), end: this.state.selectedPeriod[1]?.toJSON()})).data

    this.setState({donePrestations: data.prestations, evolution: data.evolution})
  }

  public render() {
    const { model } = this.props;
    const { donePrestations, selectedPeriod, evolution } = this.state;
    
    if (!model || !donePrestations) return <SmallLoading/>
    return (
      <MainLayout title={model.getFullName()}>
        <div className="row">
          <div className="col-xl-7 col-12 mb-3">
            <div className="app-content mb-3 fadeInDown">
              <div className="d-flex justify-content-between">
                <div className="card-title mb-0">Informations personnelles</div>
                <div className="px-3 py-2 rounded fw-bold" style={model.getStyle()}>{model.role.label}</div>
              </div>
              <div>
                <TextIcon leftIcon="fas fa-envelope">
                  {model.email}
                </TextIcon>
                {model.phone && <TextIcon leftIcon="fas fa-phone">
                  {model.phone}
                </TextIcon>}
              </div>
              {model.currentYard && <div className="mt-4">
                <TextIcon leftIcon="far fa-clock">Actuellement sur le chantier <span onClick={() => DOMService.openSidebar(SidebarRoute.YardShow, {id: model.currentYard.id})} className="text-primary fw-bold text-capitalize pointer">{model.currentYard.name}</span> à {model.currentYard.city}</TextIcon>
              </div>}
            </div>
            <div className="app-content fadeInDown animate-delay-1">
              <div className="row-end mb-6 flex-wrap">
                <div className="card-title me-auto">Prestations réalisées</div>
                <div className="row-end me-n2">
                  {this.periodSelects.map((select) => (
                    <div className="me-2">
                      <ScopeButton onClick={() => this.handleChangePeriod(select.value)} active={selectedPeriod === select.value} >
                        {select.label}
                      </ScopeButton>
                    </div>
                  ))}
                </div>
              </div>
              <div className="row">
                {OptionService.prestationTypes().map((prestationType) => {
                  return <div className="col-flex col-6 col-md mb-3">
                    <div className="decorated-icon mb-3">
                      <i className={"fas " + prestationType.icon}></i>
                    </div>
                    <div className="text-center text-s">{prestationType.label}</div>
                    <div className="fw-bold text-2 mt-auto">
                      {donePrestations.find(p => p.prestationTypeId === prestationType.id)?.total || 0}
                    </div>
                  </div>
                })}
              </div>
            </div>
          </div>
          <div className="col-xl-5 col-12 mb-3">
            <div className="app-content fadeInDown animate-delay-2">
              <UserCalendar user={model} />
            </div>
          </div>
        </div>
        <div className="app-content fadeInDown animate-delay-3 mb-4">
          <div className="card-title mb-3">Evolution des prestations effectuées</div>
          <Line
            style={{maxHeight: "450px"}}
            options={{
              maintainAspectRatio: false
            }}
            data={ChartService.buildEvolutionData(evolution)}
          />
        </div>
      </MainLayout>
    );
  }
}

export default showModel(UserShowPage, {modelClass: User}) as any