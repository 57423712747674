import SmartSwitch from '@components/input/SmartSwitch';
import FullCalendar, { EventSourceInput } from '@fullcalendar/react';
import { SidebarRoute } from '@components/layout/SidebarRouter';
import interactionPlugin from '@fullcalendar/interaction';
import { ResourceSourceInput } from '@fullcalendar/resource-common';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import Slot from '@models/Slot';
import Yard from '@models/Yard';
import EntityManager from '@services/EntityManager';
import dayjs from 'dayjs';
import groupBy from 'lodash/groupBy';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { convertHexToRGBA } from 'src/helper/ColorHelper';
import DOMService from 'src/services/DOMService';
import { sessionBloc } from '@bloc/SessionBloc';
import classNames from 'classnames';

export interface IYardCalendarProps {
  yards: Yard[]
  load: any
}

export interface IYardCalendarState {
  filterResourcesWithEvents: boolean
  mode: "select" | "split" | "erase"
}

export default class YardCalendar extends React.Component<IYardCalendarProps, IYardCalendarState> {

  leftArea

  constructor(props) {
    super(props);
    this.state = {
      filterResourcesWithEvents: true,
      mode: "select",
    }
  }

  select = async (e) => {
    if (!sessionBloc.isManager()) return null;
    
    let yard = e.resource.extendedProps.model;
    
    let newModel = new Slot({startAt: e.start, endAt: e.end, yard});
    this.openSlotForm({newModel})
  }

  componentDidMount() {
    this.leftArea = document.getElementsByClassName("fc-toolbar-chunk")[0];
    this.setState({})
  }

  onAddSlot = () => {
    DOMService.closeSidebar();
    this.reload();
  }

  eventUpdate = async (e) => {
    let slot: Slot = e.event.extendedProps.model;
    let yardId = e.event._def.resourceIds[0];
    slot.startAt = e.event.start;
    slot.endAt = e.event.end;
    slot.endAt = e.event.end;
    slot.yardId = yardId
    await EntityManager.update(slot, {only: ["startAt", "endAt", "yard"]});
    this.reload();
  }

  reload() {
    this.props.load();
  }

  buildData = () => {
    let data = {events: [], resources: []}
    let slots = this.getAllSlots();
    this.props.yards.forEach((yard, index) => {
      let mainResource = {
        id: yard.id,
        title: yard.name,
        model: yard,
        eventAllow: (dropInfo, draggedEvent) => {
          let isInYardPeriod = dropInfo.start >= yard.startAt && dropInfo.end <= yard.endAt;
          let dontOverlap    = slots.filter(s => {
            return s.userId === draggedEvent.extendedProps.model.userId && s.id !== draggedEvent.extendedProps.model.id && !s.dontOverlap(dropInfo.start, dropInfo.end)
          }).length === 0;
          return isInYardPeriod && dontOverlap;
        },
        eventColor: yard.getColor(),
        eventBackgroundColor: convertHexToRGBA(yard.getColor(), 60),
      } as ResourceSourceInput
      data.resources.push(mainResource);
      data.events.push({
        resourceId: yard.id,
        start: yard.startAt,
        end: yard.endAt,
        editable: false,
        title: yard.name,
        display: "background",
        model: yard,
        description: "",
        index: "yard" + yard.id,
      } as EventSourceInput)
      let byUsers = groupBy(yard.slots, "userId");
      Object.keys(byUsers).forEach((userId, index) => {
        byUsers[userId].forEach(slot => {
          data.events.push({
            resourceId: yard.id,
            start: slot.startAt,
            display: "block",
            end: slot.endAt,
            className: (this.state.mode === "split" && slot.getDurationWithWeek() === 1) ? "select-mode" : this.state.mode + "-mode",
            title: slot.user.getFullName(),
            description: "",
            model: slot,
            index: slot.id,
          } as EventSourceInput)
        })
      })
    });
    return data;
  }

  resourceRender = (item) => {
    let yard: Yard = item.resource.extendedProps.model;
    let compo = yard.findCompoByPrestaKey("prestation_type_reading");
    return <div className="d-flex justify-content-between" onClick={() => DOMService.openSidebar(SidebarRoute.YardShow, {id: yard.id})}>
      <div className="px-2 w-50">
        <div className="link">{item.resource.title}</div>
        <div className="text-secondary text-s">{item.resource.extendedProps.model?.city}</div>
      </div>
      <div className="px-2 ms-auto w-50 d-none d-md-block">
        <div className="text-end">
          {item.resource.extendedProps.model?.smallPeriodStr()}
        </div>
        <div className="text-end text-s text-primary">
          {(item.resource.extendedProps.model && compo) && `${compo.prestationsRate}/jour`}
        </div>
      </div>
    </div>
  }

  changeMode = (mode) => {
    this.setState({mode})
  }

  eventContent = (event: any) => {
    return <div className="text-capitalize fw-bold px-2 py-1">{event.event.title}</div>
  }

  openSlotForm(props) {
    DOMService.openSidebar(SidebarRoute.SlotForm, {...props, slots: this.getAllSlots(), onSubmit: this.onAddSlot})
  }

  getAllSlots() {
    return this.props.yards.map(y => y.slots).flat();
  }

  showSlot = async (e) => {
    if (!sessionBloc.isManager()) return null;
    let slot: Slot = e.event.extendedProps.model;
    const {id, userId, yardId, startAt, endAt} = slot;

    if (this.state.mode === "split") {
      let width = document.getElementsByClassName("fc-timeline-slot")[0].getBoundingClientRect().width
      let cut = (e.jsEvent.offsetX + (width / 2)) / width;
      let cutDay = dayjs(startAt).add(cut, "day");
      let newSlots = [
        new Slot({startAt, endAt: cutDay, userId, yardId}),
        new Slot({startAt: cutDay.toDate(), endAt, userId, yardId}),
      ];
      await EntityManager.delete(slot);
      await EntityManager.createMany(Slot, newSlots);
      this.reload();
    } else if (this.state.mode === "erase") {
      await EntityManager.delete(slot);
      this.reload();
    } else {
      this.openSlotForm({id})
    }
    
  }

  public render() {
    const {filterResourcesWithEvents, mode} = this.state;
    let data = this.buildData();
    return (
      <div className="fadeIn">
        <FullCalendar
          plugins={[ resourceTimelinePlugin, interactionPlugin ]}
          headerToolbar={{
            left: '',
            center: 'title',
            right: 'prev,next'
          }}
          buttonText={{
            today:    'Aujourd\'hui',
            month:    'Mois',
            week:     'Semaine',
            day:      'Jour',
          }}
          initialView={'resourceTimelineMonth'}
          select={this.select}
          // hiddenDays={[0, 6]}
          resourceAreaWidth={window.innerWidth > 500 ? "300px" : "150px"}
          eventResize={this.eventUpdate}
          eventChange={this.eventUpdate}
          eventClick={this.showSlot}
          businessHours
          displayEventTime={false}
          resourceAreaHeaderContent="Chantiers"
          timeZone={"fr"}
          editable={sessionBloc.isManager()}
          selectable={sessionBloc.isManager()}
          schedulerLicenseKey="0344037874-fcs-1637598111"
          dateClick={this.select}
          height={"calc(100vh - 170px)"}
          locale={"fr"}
          resources={data.resources}
          // selectOverlap={} a faire 
          filterResourcesWithEvents={filterResourcesWithEvents}
          events={data.events}
          eventContent={this.eventContent}
          resourceLabelContent={this.resourceRender}
        />
        {this.leftArea && ReactDOM.createPortal(<div className="row-flex">
          <SmartSwitch id={"filterEvents"} label="Seulement période affichée" onChange={(v) => this.setState({filterResourcesWithEvents: v})} value={filterResourcesWithEvents} />
          <div onClick={() => this.changeMode("select")} className={classNames({"btn ms-2": true, [mode === "select" ? "btn-primary" : "btn-light"]: true})}>
            <i className="fas fa-mouse-pointer"></i>
          </div>
          <div onClick={() => this.changeMode("split")} className={classNames({"btn ms-2": true, [mode === "split" ? "btn-primary" : "btn-light"]: true})}>
            <i className="fas fa-cut"></i>
          </div>
          <div onClick={() => this.changeMode("erase")} className={classNames({"btn ms-2": true, [mode === "erase" ? "btn-primary" : "btn-light"]: true})}>
            <i className="fas fa-eraser"></i>
          </div>
        </div>, this.leftArea)}
      </div>
    );
  }
}
