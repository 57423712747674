import * as React from 'react';
import CustomSelect from '../input/CustomSelect';

export interface IPerPageSelectProps {
  value: number
  onChange: (v: number) => void
}

export interface IPerPageSelectState {
}

export default class PerPageSelect extends React.Component<IPerPageSelectProps, IPerPageSelectState> {

  options = [
    {label: "15", id: 15},
    {label: "30", id: 30},
    {label: "50", id: 50},
    {label: "100", id: 100},
    {label: "1000", id: 1000},
  ];

  onChange = (v) => {
    this.props.onChange(v.id);
  }

  public render() {
    return (
      <div className="row-flex">
        <label htmlFor="" className="me-3 text-nowrap">Par Page</label>
        <CustomSelect
          options={ this.options }
          placeholder={ "Par page" }
          value={ this.options.find(o => o.id === this.props.value) }
          onChange={ this.onChange }
        />
      </div>
    );
  }
}
