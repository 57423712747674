import * as React from 'react';
import { Redirect } from 'react-router';

export interface ILogoutPageProps {
}

export default function LogoutPage (props: ILogoutPageProps) {
  return (
    <Redirect to="/login" />
  );
}
