import AsyncButton from '@components/button/AsyncButton';
import SmartInputText from '@components/input/SmartInputText';
import SmartOptionSelect from '@components/input/SmartOptionSelect';
import SmartSwitch from '@components/input/SmartSwitch';
import formModel, { IFormModelProps, IInjectedFormModelProps } from '@components/logic/FormModel';
import * as React from 'react';
import User from 'src/models/User';
import SidebarLayout from '../layout/SidebarLayout';

export interface IUserFormProps extends IInjectedFormModelProps<User> {
}

export interface IUserFormState {
}

class UserForm extends React.Component<IUserFormProps & IFormModelProps<User>, IUserFormState> {
  constructor(props: IUserFormProps & IFormModelProps<User>) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const {model, submit, submitting, id} = this.props;
    return (
      <SidebarLayout
        title={id ? "Modification d'un utilisateur" : "Création d'un utilisateur"}
        bottomArea={<AsyncButton className="btn btn-primary w-100 btn-lg" loading={submitting} onClick={submit} >{id ? "Modifier l'utilisateur" : "Créer l'utilisateur"}</AsyncButton>}
      >
        <div className="fadeInDown">
          <div className="row">
            <div className="col-md-6 col-12">
              <SmartInputText model={model} name="firstname" label="Prénom" containerClassName="mb-3" />
            </div>
            <div className="col-md-6 col-12">
              <SmartInputText model={model} name="lastname" label="Nom" containerClassName="mb-3" />
            </div>
          </div>
          <SmartInputText model={model} name="phone" label="Téléphone" containerClassName="mb-3" />
          <SmartOptionSelect model={model} name="role" label="Role" containerClassName="mb-3" />
          <SmartInputText model={model} name="email" label="Adresse Email" containerClassName="mb-3" />
          {model.id && <SmartSwitch id="accountactive" model={model} name="active" label="Compte activé" containerClassName="mb-3" />}
        </div>
      </SidebarLayout>
    );
  }
}

export default formModel<IUserFormProps>(UserForm, {modelClass: User})