import * as React from 'react';
import { Redirect, Route } from 'react-router';
import authService from '../../services/AuthService';


export default function PrivateRoute({ exact = false, path, component, role = "all", pathToRedirect = '/login' }) {
  return (
    <Route exact={exact} path={path} render={(p?) => {
      if (authService.isAuthorized(role)) return component(p)
      else return <Redirect to={pathToRedirect}/>
    }}>
    </Route>
  );
}
