import { DetailedLoading } from '@components/common/DetailedLoading';
import MainLayout from '@components/layout/MainLayout';
import { SidebarRoute } from '@components/layout/SidebarRouter';
import YardPresent from '@components/yard/YardPresent';
import Yard from '@models/Yard';
import dayjs from 'dayjs';
import * as React from 'react';
import DOMService from 'src/services/DOMService';
import PrestationCalendar from '../components/calendar/PrestationCalendar';
import showModel, { IInjectedShowModelProps, IShowModelProps } from '../components/logic/ShowModel';
import { sessionBloc } from "@bloc/SessionBloc";
import EntityManager from '@services/EntityManager';

export interface IYardShowPageProps extends IInjectedShowModelProps<Yard> {
  id
}

class YardShowPage extends React.Component<IShowModelProps<Yard>> {

  onEdit = () => {
    DOMService.openSidebar(SidebarRoute.YardForm, {id: this.props.id, onSubmit: this.onSubmit})
  }

  onSubmit = (_) => {
    DOMService.closeSidebar();
    this.props.load();
  }

  getData = () => {
    let data = {events: [], resources: []}

    this.props.model.reportsByUser.forEach(user => {
      data.resources.push({
        id: user.id,
        user,
        title: user.getFullName(),
      });
      let reports = user.reports;
      
      reports.forEach(report => {
        data.events.push({
          resourceId: report.userId,
          id: report.id.toString(),
          title: report.getDescription(),
          report,
          allDay: true,
          start: report.date,
          end: dayjs(report.date).add(1, "day").toDate(),
          backgroundColor: report.yardComposition.prestationType.color,
          borderColor: report.yardComposition.prestationType.color,
        })
      })
    })
    data.resources.push({
      id: "new",
      title: "Rapport d'un nouvel ouvrier",
    });
    return data;
  }

  openCloseModal = () => {
    DOMService.alert({
      title: "Cloturer le chantier",
      message: "Etes vous sûr de vouloir cloturer ce chantier ?",
      onConfirm: this.close
    });
  }

  close = async () => {
    this.props.model.closed = true;
    await EntityManager.update(this.props.model, {only: ["closed"]});
    DOMService.closeModal();
    this.setState({})
  }

  public render() {
    const { model } = this.props;
    
    if (!model) return <DetailedLoading/>
    let data = this.getData();
    return (
      <MainLayout
        title={<div className="row-flex">
          <h4 className="mb-0">{model.name}</h4>
          <div className={"ms-3 badge " + (model.getStateClass())}>{model.getStateText()}</div>
        </div>}
        right={ sessionBloc.isManager() && <div className="row-end">
          { !model.closed && <button onClick={this.openCloseModal} className="btn btn-info me-3">Cloturer</button>}
          <button onClick={this.onEdit} className="btn btn-primary">Modifier</button>
        </div>}
      >
        <div className="app-content fadeIn pe-3">
          <div className="row">
            <div className="col-lg-8 mb-3 overflow-auto">
              <PrestationCalendar data={data} yard={model} onChange={() => this.props.load()} />
            </div>
            <div className="col-lg-4 yard-show-present">
              <YardPresent yard={model} />
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}

export default showModel<any>(YardShowPage, {modelClass: Yard})