import Model from '@models/Model';
import Attribute from "./core/Attribute";
import AttributesCollection from "./core/AttributeCollection";
import PrestationType from './PrestationType';
import ModelAttribute from './core/ModelAttribute';
import { convertHexToRGBA } from 'src/helper/ColorHelper';

export default class Prestation extends Model {

  public label: string
  public key: string
  public price: number
  public color: string
  public prestationTypeId: number
  public prestationType: PrestationType
  public countInProgress: boolean = true
  public threshold: number

  static modelUrl = "prestations";
  public modelName = "prestation";

  public attributes = new AttributesCollection({
    label: new Attribute("label"),
    key: new Attribute("key"),
    countInProgress: new Attribute("countInProgress"),
    color: new Attribute("color"),
    price: new Attribute("price"),
    threshold: new Attribute("threshold"),
    prestationType: new ModelAttribute("prestationType", PrestationType, {submitObject: true}),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }

  thresholdText() {
    return `€${this.threshold}/jour`
  }

  getStyle() {
    return {color: this.color, backgroundColor: convertHexToRGBA(this.color || "black", 10)}
  }
}