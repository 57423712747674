import Yard from '@models/Yard';
import * as React from 'react';
import { ProgressBar } from 'react-bootstrap';
import { sessionBloc } from '@bloc/SessionBloc';
import classNames from 'classnames';
import TextIcon from '@components/common/TextIcon';

export interface IYardCardProps {
  yard: Yard
  className?: string
}

export default class YardCard extends React.Component<IYardCardProps> {
  public render() {
    const {yard, className} = this.props
    let data = yard.dayLeftData();
    return (
      <div className={classNames({"yard-card card mb-3": true, [className]: !!className})} onClick={() => sessionBloc.push(yard.url())}>
        <div>
          <div className="d-flex justify-content-between mb-3">
            <div>
              <div className="fw-bold text-capitalize">{yard.name}</div>
              <div className="text-s text-secondary">{yard.city}</div>
              <div className="mt-2">
                {yard.smallPeriodStr()}
              </div>
            </div>
            <div>
              <div className={"ms-3 badge " + (yard.getStateClass())}>{yard.getStateText()}</div>
            </div>
          </div>
          <div className="row-between">
            {yard.yardCompositions.map((compo) => (
              <div className="col-center position-relative">
                <div className="">
                  <i className={compo.prestationType.regularIcon()}></i>
                </div>
                <div className="text-l">
                  {compo.done.value}
                </div>
                <div className="mx-1 position-absolute start-100 text-nowrap text-primary text-xs top-50">
                  {compo.progressText()}
                </div>
              </div>
            ))}
            {yard.yardCompositions.length < 4 && <div></div>}
            {yard.yardCompositions.length < 3 && <div></div>}
            {yard.yardCompositions.length < 2 && <div></div>}
          </div>
        </div>
        <div className="mt-auto">
          <div className="row-between py-3">
            <ProgressBar className="flex-1 me-2" now={yard.getProgress()} />
            <div className="text-s">{yard.getProgressText()}</div>
          </div>
          <div className="row-between">
            <div title={yard.users.map(u => u.getFullName()).join(", ")}>
              <TextIcon leftIcon="fas fa-users">
                <div>{yard.users.length}</div>
              </TextIcon>
            </div>
            <div className={`bg-light-${data.className} rounded px-2 py-1 text-s text-${data.className}`}>{data.label}</div>
          </div>
        </div>
      </div>
    );
  }
}
