import Attribute from "./core/Attribute";
import AttributesCollection from "./core/AttributeCollection";
import ModelAttribute from "./core/ModelAttribute";
import Customer from "./Customer";
import Model from "./Model";
import DateAttribute from './core/DateAttribute';
import ArrayModelAttribute from './core/ArrayModelAttribute';
import YardComposition from './YardComposition';
import User from 'src/models/User';
import ManyToManyAttribute from './core/ManyToManyAttribute';
import Report from './Report';
import Slot from '@models/Slot';
import RequiredValidator from "./core/form/validators/RequiredValidator";
import dayjs from "dayjs";
import YardPrice from './YardPrice';
import capitalize from 'lodash/capitalize';

export default class Yard extends Model {

  public name: string
  public price: number
  public city: string
  public description: string
  public batch: string
  public state: string
  public startAt: Date
  public endAt: Date
  public customer: Customer
  public customerId: number
  public progress: number
  public closed: boolean
  public duration: number
  public yardCompositions: YardComposition[]
  public yardPrices: YardPrice[]
  public users: User[]
  public reports: Report[]
  public slots: Slot[]
  public priceToInvoice: number
  public reportsByUser: User[]
  public dayLeft: number
  public deletedAt: Date

  static modelUrl = "yards";
  public modelName = "yard";

  public attributes = new AttributesCollection({
    name: new Attribute("name", {validator: new RequiredValidator()}),
    city: new Attribute("city"),
    deletedAt: new Attribute("deletedAt"),
    price: new Attribute("price", {submittable: false}),
    progress: new Attribute("progress", {submittable: false}),
    duration: new Attribute("duration", {submittable: false}),
    dayLeft: new Attribute("dayLeft", {submittable: false}),
    description: new Attribute("description"),
    closed: new Attribute("closed"),
    priceToInvoice: new Attribute("priceToInvoice", {submittable: false}),
    batch: new Attribute("batch"),
    state: new Attribute("state", {submittable: false}),
    startAt: new DateAttribute("startAt"),
    endAt: new DateAttribute("endAt"),
    customer: new ModelAttribute("customer", Customer),
    yardCompositions: new ArrayModelAttribute("yardCompositions", YardComposition),
    yardPrices: new ArrayModelAttribute("yardPrices", YardPrice),
    reports: new ArrayModelAttribute("reports", Report, {submittable: false}),
    reportsByUser: new ArrayModelAttribute("reportsByUser", User, {submittable: false}),
    slots: new ArrayModelAttribute("slots", Slot, {submittable: false}),
    users: new ManyToManyAttribute("users", User, {key: "user_ids"}),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }

  getColor() {
    return Yard.colors[this.id % Yard.colors.length];
  }

  url() {
    return "/yards/" + this.id
  }

  periodStr() {
    return `${dayjs(this.startAt).format("D MMMM YYYY")} - ${dayjs(this.endAt).format("D MMMM YYYY")}`;
  }
  
  smallPeriodStr() {
    return `${dayjs(this.startAt).format("DD/MM")} au ${dayjs(this.endAt).format("DD/MM")}`;
  }

  getStateClass() {
    if (this.closed) return "bg-info";
    return this.state === "ok" ? "bg-success" : "bg-danger"
  }

  getStateText() {
    if (this.closed) return "Cloturé";
    return this.state === "ok" ? "OK" : "Retard";
  }

  getProgressRemaining() {
    return 100 - (this.getProgress())
  }

  getProgress() {
    return this.progress * 100;
  }

  getProgressText() {
    let value = this.getProgress();
    if (value > 100) value = 100;
    return value.toFixed(0) + " %"
  }

  complete() {
    return this.progress >= 100;
  }

  dayLeftData() {
    if (this.complete()) return {label: "Terminé", className: "success"}
    if (this.dayLeft === 0) return {label: "Deadline dépassé", className: "danger"}
    return {label: `${this.dayLeft} jour(s) restant(s)`, className: "primary"}
  }

  getMonthReported() {
    let month = [];
    this.yardCompositions.map(yc => yc.reports).flat().forEach(r => {
      let formatKey = dayjs(r.date).format("M/YYYY");
      let formatDate = capitalize(dayjs(r.date).format("MMMM YYYY"));
      if (!month.find((o) => o.id === formatKey)) month.push({label: formatDate, id: formatKey})
    })
    return month;
  }

  findCompoByPrestaKey(key: string) {
    return this.yardCompositions.find(yc => yc.prestationType.key === key)
  }

  static colors = ['#cb4c93', '#cb4c4c', '#4ccbb9', '#4ecb4c', '#cb7b4c', '#cbaf4c', '#5f4ccb', '#4c96cb'];

}