import { SidebarRoute } from '@components/layout/SidebarRouter';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import User from '@models/User';
import DOMService from '@services/DOMService';
import * as React from 'react';
import { convertHexToRGBA } from 'src/helper/ColorHelper';

export interface IUserCalendarProps {
  user: User
}

export default class UserCalendar extends React.Component<IUserCalendarProps> {

  // select =(e) => {
  //   let slot: Slot = new Slot({
  //     startAt: e.start,
  //     endAt: e.end,
  //     user: this.props.user
  //   });
  //   console.log(slot);
    
  //   DOMService.modal(<SlotYardModal newModel={slot} onSubmit={(newSlot) => {
  //     DOMService.closeModal();
  //     this.props.user.slots.push(newSlot);
  //     this.setState({})
  //   }} />)
  // }

  getEvents = () => {
    return this.props.user.slots.map(slot => ({
      title: slot.yard.name,
      start: slot.startAt,
      end: slot.endAt,
      yardId: slot.yardId,
      color: slot.yard.getColor(),
      backgroundColor: convertHexToRGBA(slot.yard.getColor(), 50),
    }))
  }

  // eventDetail = ({event}) => {
  //   let rc: ReportComposition = event._def.extendedProps.rc;
  //   let prestationLabel: ReportComposition = event._def.extendedProps.prestationLabel;
    
  //   return <div className="p-1">
  //     <div className="fw-bold">{event.title}</div>
  //     <div className="mb-1">{rc.quantity} {prestationLabel}</div>
  //   </div>;
  // };

  public render() {
    // const {user} = this.props;
    return (
      <FullCalendar
        plugins={ [dayGridPlugin, interactionPlugin] }
        initialView='dayGridMonth'
        headerToolbar={ {
          left: "title",
          end: "prev,next",
        } }
        schedulerLicenseKey="0344037874-fcs-1637598111"
        contentHeight={"400px"}
        locale={"fr"}
        hiddenDays={[0, 6]}
        displayEventTime={false}
        eventClick={(e) => DOMService.openSidebar(SidebarRoute.YardShow, {id: e.event.extendedProps.yardId})}
        // select={this.select}
        // dateClick={this.select}
        // selectable={true}
        events={this.getEvents()}
      
    />
    );
  }
}
