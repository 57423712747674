import AsyncButton from '@components/button/AsyncButton';
import SmartInputTextarea from '@components/input/SmartInputTextarea';
import SidebarLayout from '@components/layout/SidebarLayout';
import formModel, { IFormModelProps, IInjectedFormModelProps } from '@components/logic/FormModel';
import UserSelect from '@components/modelSelect/UserSelect';
import YardCompositionSelect from '@components/modelSelect/YardCompositionSelect';
import dayjs from 'dayjs';
import * as React from 'react';
import Report from '../../models/Report';
import ReportCompositionForm from './ReportCompositionForm';
import Yard from '@models/Yard';
import DOMService from '@services/DOMService';
import TextIcon from '@components/common/TextIcon';
import ReportComposition from '@models/ReportComposition';
import EntityManager from '@services/EntityManager';

export interface IReportFormProps extends IInjectedFormModelProps<Report> {
  yard: Yard
}

class ReportForm extends React.Component<IReportFormProps & IFormModelProps<Report>, any> {

  constructor(props: IReportFormProps & IFormModelProps<Report>) {
    super(props);

    this.state = {
    }
    this.props.yard.yardPrices.forEach(yardPrice => {
      if (!this.props.model.reportCompositions.find(compo => compo.yardPriceId === yardPrice.id)) this.props.model.reportCompositions.push(new ReportComposition({yardPrice}));
    });
  }

  submit = () => {
    let total = 0;
    let remaining = this.props.model.yardComposition.remaining();
    this.props.model.reportCompositions.filter(compo => compo.yardPrice.prestation.prestationTypeId === this.props.model.yardComposition.prestationTypeId).forEach(compo => {
      if (compo.yardPrice.countAsUsual()) {
        total += (compo.quantity || 0) - (compo.originalOf("quantity") || 0)
      }
    })
    
    if (remaining < total) {
      DOMService.setToaster(<div className="col-center">
        <i className="fas fa-warning fa-5x text-secondary"></i>
        <div className="text-center mb-4">
          Attention, le nombre de prestation déclaré ici est supérieur au nombre de prestations restantes à réaliser.
        </div>
        <div className="mb-3 fw-bold">
          Que souhaitez-vous faire ?
        </div>
        <div className="row-center">
          <button className="btn btn-secondary mx-2">Annuler</button>
          <button className="btn btn-warning mx-2" onClick={() => this.props.submit()}>Poursuivre</button>
          {/* <button className="btn btn-primary mx-2">Corriger automatiquement</button> */}
        </div>
      </div>)
    } else {
      this.props.submit();
    }
  }

  delete = async () => {
    DOMService.setToaster(<div className="p-3">
      <div>Etes vous sur  de vouloir supprimer ce rapport ?</div>
      <div className="row-center mt-3">
        <div className="btn btn-light mx-2">Annuler</div>
        <div onClick={() => {
      EntityManager.delete(this.props.model).then(() => {
        DOMService.closeModal();
        this.props.onSubmit(this.props.model);
      });
    }} className="btn btn-danger mx-2">Supprimer</div>
      </div>
    </div>);
    
  }

  public render() {
    const {model, submitting, yard, id} = this.props;
    return (
      <SidebarLayout
        title={id ? "Modification d'un rapport" : "Création d'un rapport"}
        action={<div>
          <div onClick={this.delete} className="my-n2 small-btn-icon">
            <i className="fas fa-trash"></i>
          </div>
        </div>}
        bottomArea={<AsyncButton className="btn btn-primary w-100 btn-lg" loading={submitting} onClick={this.submit} >{id ? "Modifier le rapport" : "Créer le rapport"}</AsyncButton>}
      >
        <div className="fadeInDown">
          <div className="mb-3">
            <TextIcon leftIcon="fas fa-calendar-alt">{dayjs(model.date).format("D MMMM YYYY")}</TextIcon>
          </div>
          <UserSelect model={model} label="Employé concerné" name="user" placeholder="Choisir un employé" containerClassName="mb-3" filterModels={(models) => models.filter(m => !m.isAdmin)}/>
          <YardCompositionSelect label="Type de prestation" model={model} options={yard.yardCompositions} name="yardComposition" placeholder="Type de prestation" containerClassName="mb-3"/>
          <ReportCompositionForm yardComposition={model.yardComposition} report={model}/>
          <SmartInputTextarea model={model} name="comment" label="Commentaire" containerClassName="mb-3" />
        </div>
      </SidebarLayout>
    );
  }
}

export default formModel<IReportFormProps>(ReportForm, {modelClass: Report})