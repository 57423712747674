
export function currentDayRange() {
  let start = new Date();
  let end = new Date();
  start.setHours(0);
  start.setMinutes(0);
  start.setSeconds(0);
  end.setHours(23);
  end.setMinutes(59);
  end.setSeconds(59);
  return [start, end]
}

export function currentWeekRange() {
  let start = getMonday(new Date());
  let end = new Date();
  start.setHours(0);
  start.setMinutes(0);
  start.setSeconds(0);
  return [start, end]
}

export function currentMonthRange() {
  let date = new Date();
  let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return [firstDay, lastDay]
}

function getMonday(d) {
  d = new Date(d);
  let day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? -6:1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}

export function diffDay(date1, date2) {
  const ONE_DAY = 1000 * 60 * 60 * 24;

    // Calculate the difference in milliseconds
    const differenceMs = Math.abs(date1 - date2);

    // Convert back to days and return
  return Math.round(differenceMs / ONE_DAY);
}