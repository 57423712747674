import { sessionBloc } from '@bloc/SessionBloc';
import TextIcon from '@components/common/TextIcon';
import Yard from '@models/Yard';
import * as React from 'react';
import { ProgressBar } from 'react-bootstrap';
import { toEuro } from '../../helper/PriceHelper';
import YardProgress from './YardProgress';

export interface IYardPresentProps {
  yard: Yard
}

export default class YardPresent extends React.PureComponent<IYardPresentProps> {

  
  public render() {
    const {yard} = this.props
    return (
      <div className="d-flex flex-column">
        {sessionBloc.isAdmin() && <div className="mb-3 gradient-bg rounded text-white p-3">
        { !yard.closed ? <div className="row-between">
          <div className="">
            <div className="text-s">Valeur estimée</div>
            <div  className="fw-bold text-l">{toEuro(yard.price)}</div>
          </div>
          <div className="text-end">
            <div className="text-s">Réalisé</div>
            <div  className="fw-bold text-l">{toEuro(yard.priceToInvoice)}</div>
          </div>
        </div> : <div className="row-between">
            <div className="text-s">Valeur du chantier</div>
            <div  className="fw-bold text-l">{toEuro(yard.priceToInvoice)}</div>
          </div>
        }
      </div>}
        {/* <div className="mb-2">
          <TextIcon iconClassName="text-2" spacing={3} leftIcon="fas fa-calendar-alt">
            {yard.periodStr()}
          </TextIcon>
        </div> */}
        {/* <TextIcon iconClassName="text-2" iconStyle={{marginLeft: "0.125rem"}} spacing={3} leftIcon="fas fa-map-marker-alt" className="mb-2">
          <div>
            <div className="text-capitalize">{yard.city}</div>
          </div>
        </TextIcon> */}
        <div className="mb-3">
          <div className="mb-2">
            <div className="text-s text-secondary">Période</div>
            <div>{yard.periodStr()}</div>
          </div>
          {yard.city && <div className="mb-2">
            <div className="text-s text-secondary">Secteur</div>
            <div>{yard.city}</div>
          </div>}
          {yard.description && <div className="mb-2">
            <div className="text-s text-secondary">Description</div>
            <div className="multiline">{yard.description}</div>
          </div>}
          {yard.users.length > 0 && <div className="">
            <div className="text-s text-secondary">Responsables</div>
            {yard.users.map(user => (
              <div key={user.getElementId()} className="mb-2 rounded pointer text-capitalize">
                <TextIcon leftIcon="fas fa-user-tie">
                  {user.getFullName()}
                </TextIcon>
              </div>
            ))}
          </div>}
        </div>
        <div className="">
          <YardProgress className="mb-5" yard={yard} />
          <div className="mb-5">
            <div className="row-between mb-2 fw-bold">
              <div className="">Global</div>
              <div className="">{yard.getProgressText()}</div>
            </div>
            <ProgressBar className="w-100" now={yard.progress * 100}/>
          </div>
          {sessionBloc.isAdmin() && <div className="">
            <div className="text-s text-secondary">Prix unitaires des prestations</div>
            {yard.yardPrices.map((price, index) => (
              <div key={price.getElementId()} className="row-between">
                <div>{price.prestation.label}</div>
                <div className="">{toEuro(price.unitPrice)}</div>
              </div>
            ))}
          </div>}
          {/* <div className="position-relative mt-auto">
            <div style={{width: "150px", margin: "auto"}}>
              <Doughnut data={{
                datasets: [{
                  label: "test",
                  data: [yard.getProgress(), yard.getProgressRemaining()],
                  backgroundColor: ["#3ed589", "#e1e3ed"],
                  borderColor: ["transparent", "transparent"]
                }],
                
              }} options={{
                plugins: {
                  legend: {
                    display: false
                  },
                }
              }} />
            </div>
            <div className="doughnut-text fw-bold">{yard.getProgressText()}</div>
          </div> */}
        </div>
      </div>
    );
  }
}
