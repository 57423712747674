import AsyncButton from '@components/button/AsyncButton';
import { DummyForm } from '@components/form/DummyForm';
import SmartInputText from '@components/input/SmartInputText';
import LoginResponse from '@services/apiResponse/LoginResponse';
import authService from '@services/AuthService';
import classNames from 'classnames';
import * as React from 'react';
import { Redirect } from 'react-router';
import { ILoginPageProps } from './LoginPage';

export interface IFirstLoginPageProps {
}

export interface IFirstLoginPageState {
  [arg: string]: any
  email: string
  password: string
  isChecking: boolean
  redirect: boolean
  errors: {[name: string]: string}
}

export default class FirstLoginPage extends React.Component<IFirstLoginPageProps, IFirstLoginPageState> {
  constructor(props: ILoginPageProps) {
    super(props);

    this.state = {
      email: "",
      password: "",
      isChecking: false,
      redirect: false,
      errors: null
    }
  }

  onKeyPressed = (e) => {
    if (e.key === "Enter") this.submit()
  }

  handleChange = (value, e) => {
    this.setState({[e.target.name]: value, errors: null})
  }

  onKeyPress = (e) => {
    if (e.key === "Enter") this.submit()
  }

  onLogin = async (response: LoginResponse) => {
    if (response.ok) {
      this.setState({redirect: true})
    } else {
      this.setState({errors: response.errors, isChecking: false});
    }
  }

  submit = async () => {
    let response: LoginResponse = await authService.login({email: this.state.email, password: this.state.password})
    this.onLogin(response)
  }

  public render() {
    const {email, password, errors, redirect} = this.state;
    if (redirect) return <Redirect to="/" />
    return (
      <div className="d-flex min-vh-100">
        <div className="m-auto p-8 rounded-2 shadow" style={{width: "600px"}}>
          <h3 className="fw-bold mb-5 text-center">
          Finalisation de votre compte
          </h3>
          <DummyForm submit={this.submit} formProps={{noValidate: true}}>
            {(loading, validity) =>
              <>
                <SmartInputText type="email" containerClassName="mb-3" className={classNames({"is-invalid": !!errors})} label="Adresse Email" value={email} name="email" required onChange={this.handleChange} autoFocus/>
                <SmartInputText id="password" type="password" className={classNames({"is-invalid": !!errors})} label="Créer mon mot de passe" name="password" value={password} required onChange={this.handleChange}/>
                {errors && <div className="text-danger mt-1">{errors.credential}</div>}
                <div className="text-end link mt-3 mb-5">
                  <div>Mot de passe oublié ?</div>
                </div>
                <div className="text-center">
                  <AsyncButton  loading={loading}  type="submit" className="btn btn-primary m-auto w-100 text-dark-primary">
                    CREER MON COMPTE
                  </AsyncButton>
                </div>
              </>
            }
          </DummyForm>
        </div>
      </div>
    );
  }
}
