import AppSidebar from '@components/layout/AppSidebar';
import BaseModal from '@components/layout/BaseModal';
import BaseToaster from '@components/layout/BaseToaster';
import DialogBase from '@components/layout/DialogBase';
import { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import App from './App';
import './assets/sass/app.scss';
import { consumeSessionBloc } from './bloc/SessionBloc';
import FirstLoginPage from './pages/FirstLoginPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';
import ResetPasswordConfirmPage from './pages/ResetPasswordConfirmPage';
import AuthService from './services/AuthService';

class Root extends Component<any, any> {

  constructor(props) {
    super(props);

    this.state = {
      init: false
    }
  }

  async componentDidMount() {
    if (AuthService.hasToken()) {
      await AuthService.me();
    }
    this.setState({init: true});
  }

  render() {
    if (!this.state.init) return <></>
    document.body.className = "theme--default"
    return (
      <div>
        <Switch>
          <Route path="/login" component={LoginPage} />
          <Route path="/firstlog" component={FirstLoginPage} />
          <Route path="/forgot_password" component={ForgotPasswordPage} />
          <Route path="/reset_password" component={ResetPasswordConfirmPage} />
          <Route path="/logout" component={LogoutPage} />
          {!this.props.logged && <Redirect to="/login" />}
          <Route path="/" render={() => <App/>}/>
        </Switch>
        <BaseModal/>
        <DialogBase/>
        <AppSidebar/>
        <BaseToaster/>
      </div>
    );
  }
}

export default consumeSessionBloc(Root);
