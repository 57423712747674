import Model from "../Model";
import BaseModelAttribute, { BaseModelAttributeOptions } from './BaseModelAttribute';

export default class ModelAttribute extends BaseModelAttribute {

  public idValue: any;

  constructor(name: string, className: any, options?: BaseModelAttributeOptions) {
    super(name, className, options);
    this.key = options?.submitObject ? this.name : this.nameOfId();
  }

  public toApiData(parentModel: Model, stringify = false) {
    let value;
    if (this.submitObject) {
      value = parentModel[this.name]?.getApiParam();
    } else {
      value = this.idValue
    }
    if (stringify) value = JSON.stringify(value);
    return value;
  }

  public fill(parentModel: any, json) {
    super.fill(parentModel, json)
    let nameOfId = this.nameOfId();
    let idValue = json[nameOfId] || json[this.name]?.id;

    parentModel.getAttribute(this.name).setIdValue(idValue);
    Object.defineProperty(parentModel, nameOfId, {
      get: () => {
        return parentModel.getAttribute(this.name).getIdValue();
      },
      set: (val) => {
        let previousValue = parentModel.getAttribute(this.name).getIdValue();
        parentModel.getAttribute(this.name).setIdValue(val);
        let newValue = parentModel.getAttribute(this.name).getIdValue();
        this.change(previousValue, newValue);
      }
    });
  }

  nameOfId() {
    return this.name + "Id";
  }

  public change(previousValue?, newValue?) {
    if (newValue instanceof this.className) this.idValue = newValue.id;
    if (newValue == null) this.idValue = null;
    super.change(previousValue, newValue);
  }

  setIdValue(value) {
    return this.idValue = value;
  }

  getIdValue() {
    return this.idValue;
  }

}