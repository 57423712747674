
import React from 'react';
import User from '../models/User';
import authService from '../services/AuthService';
import DOMService from '../services/DOMService';
import EntityManager from '../services/EntityManager';
import SessionService from '../services/SessionService';
import { withRouter } from 'react-router';

export interface entrustTiers {
  firstname: string
  lastname: string
  phone: string
}

export interface ISessionBlocState {
  user: User | undefined
  logged: boolean
  theme: string
}

export interface ISessionBlocConsumer extends ISessionBlocState {

}

export const Context = React.createContext({})

export let sessionBloc: SessionBloc

class SessionBloc extends React.Component<any, ISessionBlocState> {


  constructor(props: any) {
    super(props)
    sessionBloc = this

    this.state = {
      user: null,
      logged: false,
      theme: localStorage.getItem("theme") || "theme--default"
    }

  }

  get user() { return this.state.user }

  async init() {
    if (this.hasToken()) await this.me()
  }

  isAdmin() {
    return this.user.isAdmin;
  }

  isManager() {
    return this.user.isManager || this.user.isAdmin;
  }

  public async me() {
    let response = await authService.usersManager.me();
    this.setSessionData(response.model);
    if (!response.ok) this.deleteToken();
    return true;
  }

  public hasToken() {
    return SessionService.getToken() !== null;
  }

  public isAuthenticated(): boolean {
    return !!this.state.user;
  }

  public isAuthorized(role: any): boolean {
    return this.isAuthenticated() && (role === "all")
  }
  public storeToken(token: string) {
    SessionService.setCookie(SessionService.cookieName, token, 30);
  }

  logged() {
    return !!this.state.user;
  }

  public deleteToken(): void {
    SessionService.eraseCookie(SessionService.cookieName)
    this.setState({user: null});
  }

  logout() {
    authService.logout();
    this.setState({logged: false, user: null});
    DOMService.setToaster("Nous espérons vous revoir bientôt 😊")
  }

  public getUserId = () => this.state.user?.id;

  public async setSessionData(user: User) {
    this.setState({user, logged: true});
  }

  public setUser(user: User) {
    this.setState({user});
  }

  switchTheme = () => {
    let theme: any = this.state.theme === "theme--default" ? "theme--dark" : "theme--default"
    localStorage.setItem("theme", theme);
    this.setState({theme})
  }

  updateUser(attr, value) {
    this.user[attr] = value;
    EntityManager.update<User>(this.state.user, {only: [attr]});
  }

  push(url) {
    this.props.history.push(url)
  }

  public render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    )
  }
}

export default withRouter(SessionBloc);

export function consumeSessionBloc(Component) {

  return class C extends React.Component<any> {

    render() {
      return (
        <Context.Consumer>
          { (context) => (
            <Component {...this.props } {...context}/>
          )}
        </Context.Consumer>
      )
    }
  }
}
