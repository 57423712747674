import PasswordUpdatePage from '@components/common/PasswordUpdatePage'
import CustomerForm from '@components/form/CustomerForm'
import PrestationForm from '@components/form/PrestationForm'
import ReportForm from '@components/form/ReportForm'
import SlotForm from '@components/form/SlotForm'
import UserForm from '@components/form/UserForm'
import YardForm from '@components/form/YardForm'
import YardShowSidebar from '@components/yard/YardShowSidebar'
import DOMService from '../../services/DOMService'
import CustomRouter from "./CustomRouter"

export enum SidebarRoute {
  YardForm, CustomerForm, ReportForm, UserForm, PrestationForm, YardShow, SlotForm, PasswordUpdatePage
}

class SidebarRouter extends CustomRouter<SidebarRoute> {

  constructor() {
    super({
      [SidebarRoute.YardForm]:                 YardForm,
      [SidebarRoute.ReportForm]:               ReportForm,
      [SidebarRoute.CustomerForm]:             CustomerForm,
      [SidebarRoute.UserForm]:                 UserForm,
      [SidebarRoute.PrestationForm]:           PrestationForm,
      [SidebarRoute.YardShow]:                 YardShowSidebar,
      [SidebarRoute.SlotForm]:                 SlotForm,
      [SidebarRoute.PasswordUpdatePage]:       PasswordUpdatePage,
    })
  }

  showError(error) {
    DOMService.sidebarLayoutRef.setState({error});
  }
}

export default new SidebarRouter()
