import * as React from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import DOMService from '../../services/DOMService';
import SidebarRouter from './SidebarRouter';

export interface IAppSidebarProps {
  children?: any
}

export interface IAppSidebarState {
}

export default class AppSidebar extends React.Component<IAppSidebarProps, any> {

  constructor(props: IAppSidebarProps) {
    super(props);
    DOMService.sidebarRef = this;
    this.state = {
      show: false,
      style: {maxWidth: "700px"},
    }
  }

  public show() {
    this.setState({show: true});
  }

  public close() {
    this.setState({show: false})
  }

  public render() {
    
    const { show, defaultRoute, props, style } = this.state;
    return <Offcanvas show={show} onHide={() => this.close()} placement={"end"} style={style} className="app-sidebar">
      <SidebarElement defaultRoute={defaultRoute} routeProps={props} close={() => this.close()} />
    </Offcanvas>
  }
}
class SidebarElement extends React.Component<any, any> {

  renderChild = () => {
    this.forceUpdate();
  }

  componentDidMount() {
    SidebarRouter.listen(this.renderChild)
  }

  componentWillUnmount() {
    SidebarRouter.dispose(this.renderChild)
  }

  render() {
    let length  = SidebarRouter.stacks.length

    return <>
      <div className="app-sidebar-body">
        {SidebarRouter.stacks.map((stack, index) => (
          <div key={stack.route + "stack"} className={(index + 1 === length) ? "" : "d-none"}>{SidebarRouter.renderRoute(stack.route, stack.props)}</div>
        ))}
      </div>
    </>
  }
}
