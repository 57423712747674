import * as React from 'react';
import Customer from '../../models/Customer';
import ModelSelect, { IModelSelectProps } from './ModelSelect';

export default class CustomerSelect extends React.Component<IModelSelectProps> {

  formatOptionLabel = (model: Customer) => {
    return <div className="row-flex">
      <div className="me-3 capitalize">
        <div>{model.name}</div>
      </div>
      <div>
        <div className="badge bg-primary">{model.city}</div>
      </div>
    </div>
  }

  render() {
    return (
      <ModelSelect<Customer> {...this.props} formatOptionLabel={this.formatOptionLabel} modelClass={Customer} />
    );
  }

}
