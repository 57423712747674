import { OptionAttribute } from '@models/core/OptionAttribute';
import Slot from '@models/Slot';
import { convertHexToRGBA } from 'src/helper/ColorHelper';
import { diffDay } from 'src/helper/DateHelper';
import { sessionBloc } from '../bloc/SessionBloc';
import ArrayModelAttribute from './core/ArrayModelAttribute';
import Attribute from './core/Attribute';
import AttributesCollection from './core/AttributeCollection';
import Model from './Model';
import Option from './Option';
import Report from './Report';
import Yard from 'src/models/Yard';
import ModelAttribute from './core/ModelAttribute';


export default class User extends Model {

  public firstname: string
  public lastname: string
  public email: string
  public phone: string
  public type: string
  public password: string;
  public currentYard: Yard;
  public isAdmin: boolean;
  public isManager: boolean;
  public active: boolean;
  public role: Option;
  public slots: Slot[]
  public reports: Report[]
  
  static modelUrl = "users";
  public modelName = "user";

  public attributes = new AttributesCollection({
    firstname: new Attribute("firstname"),
    lastname: new Attribute("lastname"),
    email: new Attribute("email"),
    phone: new Attribute("phone"),
    active: new Attribute("active"),
    isAdmin: new Attribute("isAdmin", {submittable: false}),
    isManager: new Attribute("isManager", {submittable: false}),
    role: new OptionAttribute("role", {optionType: "user_role"}),
    password: new Attribute("password"),
    currentYard: new ModelAttribute("currentYard", Yard, {submittable: false}),
    donePrestations: new Attribute("donePrestations", {submittable: false}),
    slots: new ArrayModelAttribute("slots", Slot, {submittable: false}),
    reports: new ArrayModelAttribute("reports", Report, {submittable: false}),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }

  public getFullName(): string {
    let str = [this.firstname, this.lastname].filter(s => s);
    return str.join(" ");
  }

  public isMe = (): boolean => sessionBloc.state.user.id === this.id;

  public getStyle() {
    return {color: this.role.color, backgroundColor: convertHexToRGBA(this.role.color || "black", 10)}
  }

  getProfitability({start, end}) {
    let total = 0;
    let isProfitable = 0;
    let reportCount = 0;
    let days = diffDay(start, end);
    this.reports.forEach(report => {
      if (report.isBetween({start, end})) {
        total += report.profitability.amount;
        reportCount++;
        isProfitable += report.profitability.amount / report.profitability.ratio;
      }
    })

    let divider = Math.min(reportCount, days);
    return {moy: divider ? (total / divider).toFixed(0) : "0", isProfitable: (isProfitable / divider) >= 1};
  }

}