import SmartSelect from '@components/input/SmartSelect';
import { SidebarRoute } from '@components/layout/SidebarRouter';
import EntityManager from '@services/EntityManager';
import * as React from 'react';
import Model from 'src/models/Model';
import AddOnFly from './AddOnFly';

export interface IModelSelectProps {
  modelClass?: any
  placeholder?: string
  model?
  value?
  containerClassName?
  onChange?
  name?
  label?
  noSearch?
  onClear?
  formatOptionLabel?
  allOption?: {label: any, value: any}
  attributeRef?
  filterModels?: (models: any[]) => any[]
  addOnFly?: {attribute: string, formRoute: SidebarRoute}
  options?
  params?
}

export interface IModelSelectState<M> {
  models?: M[]
  loaded: boolean
}

export default class ModelSelect<M extends Model> extends React.Component<IModelSelectProps, IModelSelectState<M>> {

  static defaultProps = {
    filterModels: (m) => m,
  };

  constructor(props: IModelSelectProps) {
    super(props);

    this.state = {
      models: props.options || [],
      loaded: !!props.options
    }
  }

  onOpen = async () => {
    const {modelClass, params, options} = this.props;
    if (!this.state.loaded && !options) this.setState({models: (await EntityManager.all<M>(modelClass, {params})).models, loaded: true})
  }

  customRender = (child) => {
    const { model, addOnFly } = this.props;
    return <div className="row-flex">
      <div className="w-100">{child}</div>
      <AddOnFly attribute={addOnFly.attribute} formRoute={addOnFly.formRoute} parentModel={model}/>
    </div>
  }

  render() {
    const { onClear, model, name, containerClassName, label, placeholder, onChange, formatOptionLabel, attributeRef, addOnFly, filterModels, allOption, value, noSearch } = this.props;
    const { models } = this.state;
    return (
      <SmartSelect
        containerClassName={containerClassName}
        filterRules={(o: M, s: string) => !!o[attributeRef].match(new RegExp(s,"gi"))}
        renderOption={formatOptionLabel}
        equalityRule={(s, o) => s.id === o.id}
        placeholder={placeholder}
        onChange={onChange}
        model={model}
        label={label}
        onOpen={this.onOpen}
        noSearch={noSearch}
        name={name}
        value={value}
        allOption={allOption}
        options={filterModels(models)}
        onClear={onClear}
        customRender={addOnFly ? this.customRender : null}
      />
    );
  }

}
