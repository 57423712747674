import User from '@models/User';
import CustomerPage from './pages/CustomerPage';
import HomePage from './pages/HomePage';
import PlanningPage from './pages/PlanningPage';
import SettingPage from './pages/SettingPage';
import UsersPage from './pages/UsersPage';
import YardPage from './pages/YardPage';


const getRoutes = (user: User) => {
  const routes = [
    {
      icon: "fa-chart-pie",
      label: "Dashboard",
      url: "/",
      page: HomePage,
      exact: true,
      onlyAdmin: true
    },{
      icon: "fa-hard-hat",
      label: "Chantiers",
      url: "/yards",
      page: YardPage,
    },{
      icon: "fa-handshake",
      label: "Clients",
      url: "/customers",
      page: CustomerPage,
      exact: true,
      onlyAdmin: true
    },{
      icon: "fa-calendar-alt",
      label: "Planning",
      url: "/plannings",
      page: PlanningPage,
      exact: true
    },{
      icon: "fa-users",
      label: "Utilisateurs",
      url: "/users",
      page: UsersPage,
    },{
      icon: "fa-cogs",
      label: "Paramètres",
      url: "/settings",
      page: SettingPage,
      exact: true
    }
    //   icon: "fa-power-off",
    //   label: "Déconnexion",
    //   url: "/logout",
    //   page: LogoutPage,
    //   exact: true
    // }
  ];
  return routes.filter(r => !r.onlyAdmin || user.isAdmin)
} 

export default getRoutes;