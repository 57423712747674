import React from "react";
import SmartInput2, { ISmartInputProps } from "./SmartInput2";

export interface ISmartInputTextProps extends ISmartInputProps {
}

function SmartInputText(props: ISmartInputTextProps) {

  const {
    maxLength,
    minLenght,
    containerClassName,
    manager,
    className,
    autoFocus,
    errorMessage
  } = props;
  return (
    <div className={containerClassName}>
      { manager.renderLabel()}
      {
        manager.wrapWithIcon(
          <input
            value={props.value}
            name={props.name}
            type={props.type}
            disabled={props.disabled}
            id={props.id}
            required={props.required}
            placeholder={props.placeholder}
            onBlur={props.onBlur}
            style={props.style}
            className={className + " form-control"}
            onKeyDown={ manager.onKeyDown }
            onChange={ manager.handleChange }
            maxLength={maxLength}
            minLength={minLenght}
            autoFocus={autoFocus}
          />
        )
      }
      {errorMessage && <div className="text-danger text-s mt-1">{errorMessage}</div>}
      {props.children}
    </div>
  );
}
SmartInputText.whyDidYouRender = true;

export default SmartInput2<ISmartInputTextProps, any>(SmartInputText)
