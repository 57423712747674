import Attribute from "./core/Attribute";
import AttributesCollection from "./core/AttributeCollection";
import Model from "./Model";
import RequiredValidator from './core/form/validators/RequiredValidator';

export default class Customer extends Model {

  public name: string
  public email: string
  public phone: string
  public comment: string
  public address: string
  public city: string
  public cp: string

  static modelUrl = "customers";
  public modelName = "customer";

  public attributes = new AttributesCollection({
    name: new Attribute("name", {validator: new RequiredValidator()}),
    email: new Attribute("email"),
    phone: new Attribute("phone"),
    address: new Attribute("address"),
    city: new Attribute("city"),
    cp: new Attribute("cp"),
    comment: new Attribute("comment"),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }

  cpAndCity() {
    return [this.cp, this.city].join(" ")
  }

}