import AsyncButton from '@components/button/AsyncButton';
import SmartDateRangePicker from '@components/input/SmartDateRangePicker';
import SmartInputText from '@components/input/SmartInputText';
import SmartInputTextarea from '@components/input/SmartInputTextarea';
import { SidebarRoute } from '@components/layout/SidebarRouter';
import formModel, { IFormModelProps, IInjectedFormModelProps } from '@components/logic/FormModel';
import CustomerSelect from '@components/modelSelect/CustomerSelect';
import User from '@models/User';
import Yard from '@models/Yard';
import YardComposition from '@models/YardComposition';
import OptionService from '@services/OptionService';
import * as React from 'react';
import YardPrice from '../../models/YardPrice';
import SidebarLayout from '../layout/SidebarLayout';
import YardSubForm from './YardSubForm';
import YardUserForm from './YardUserForm';

export interface IYardFormProps extends IInjectedFormModelProps<Yard> {
}

export interface IYardFormState {
}

class YardForm extends React.Component<IYardFormProps & IFormModelProps<Yard>, IYardFormState> {
  constructor(props: IYardFormProps & IFormModelProps<Yard>) {
    super(props);

      OptionService.prestations.forEach(presta => {
        if (!props.model.yardPrices.find(item => item.prestationId === presta.id)) props.model.yardPrices.push(new YardPrice({prestation: presta}));
      })
      OptionService.prestationTypes().forEach(prestaType => {
        if (!props.model.yardCompositions.find(item => item.prestationTypeId === prestaType.id)) props.model.yardCompositions.push(new YardComposition({prestationType: prestaType}));
      })

    this.state = {
    }
  }

  public render() {
    const {model, submit, submitting, id} = this.props;
    
    return (
      <SidebarLayout
        title={id ? "Modification d'un chantier" : "Création d'un chantier"}
        bottomArea={<AsyncButton className="btn btn-primary w-100 btn-lg" loading={submitting} onClick={submit} >{id ? "Modifier le chantier" : "Créer le chantier"}</AsyncButton>}
      >
        <div className="fadeInDown">
          <div className="row ">
            <div className="col-md-6 col-12">
              <SmartInputText model={model} name="name" label="Nom" containerClassName="mb-3" />
            </div>
            <div className="col-md-6 col-12">
              <SmartInputText model={model} name="batch" label="Numéro de lot" containerClassName="mb-3" />
            </div>
          </div>
          <div className="">
            <SmartInputText model={model} name="city" label="Secteur" containerClassName="mb-3 " />
            <CustomerSelect label="Client" model={model} name="customer" placeholder="Choisir un client" containerClassName="mb-3" addOnFly={{
              attribute: "customer",
              formRoute: SidebarRoute.CustomerForm
            }} />
          </div>
          <SmartInputTextarea model={model} name="description" label="Description" containerClassName="mb-3 " />
          <SmartDateRangePicker leftIcon={<i className="fas fa-calendar-alt"></i>} model={model} names={["startAt", "endAt"]}  containerClassName="mb-3 "/>
          <div className="mb-3 ">
            <YardUserForm model={model} attribute={"users"} submodelClass={User} />
            <YardSubForm yard={model} />
          </div>
        </div>
      </SidebarLayout>
    );
  }
}

export default formModel<IYardFormProps>(YardForm, {modelClass: Yard})
