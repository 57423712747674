import SmartInputNumber from '@components/input/SmartInputNumber';
import Report from '@models/Report';
import * as React from 'react';
import ReportComposition from '../../models/ReportComposition';
import YardComposition from '@models/YardComposition';

export interface IReportCompositionFormProps {
  report: Report
  yardComposition: YardComposition
}

export interface IReportCompositionFormState {
}

export default class ReportCompositionForm extends React.Component<IReportCompositionFormProps, IReportCompositionFormState> {

  constructor(props: IReportCompositionFormProps) {
    super(props);

    this.state = {
    }
  }

  checkQuantity(value, remaining) {
    return value > remaining ? remaining : value;
  }

  public render() {
    const {report, yardComposition} = this.props;
    
    return <>
      {report.reportCompositions.filter(compo => compo.yardPrice.prestation.prestationTypeId === yardComposition.prestationTypeId).map((compo: ReportComposition) => {
        let remaining = report.yardComposition.remaining();
        let isComplete = report.yardComposition.isComplete();
        let remainingText = compo.yardPrice.countAsUsual() ? `${remaining} restant(s)` : "";
        return <div className="row-flex mb-3">
          <div>
            <div>{compo.yardPrice.prestation.label}</div>
            {isComplete ? <div className="ms-auto me-3 text-s text-success">Complété</div> : <div className="ms-auto me-3 text-s text-primary">{remainingText}</div>}
          </div>
          <SmartInputNumber model={compo} name="quantity" containerClassName="ms-auto me-3" placeholder="Quantité" />
          <SmartInputNumber model={compo} name="callingCard" placeholder="Avis de passage"/>
        </div>
      })}
    </>
  }
}
