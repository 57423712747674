import Navbar from '@components/layout/Navbar';
import Prestation from '@models/Prestation';
import OptionService from '@services/OptionService';
import dayjs from "dayjs";
import 'dayjs/locale/fr';
import { Component } from 'react';
import { Switch } from 'react-router';
import AdminRouter from './AdminRouter';
import './assets/sass/app.scss';
import { consumeSessionBloc } from './bloc/SessionBloc';
import PrivateRoute from './components/logic/PrivateRoute';
import UserShowPage from './pages/UserShowPage';
import YardShowPage from './pages/YardShowPage';
import AuthService from './services/AuthService';
import EntityManager from './services/EntityManager';
import classNames from 'classnames';
import { sessionBloc } from '@bloc/SessionBloc';
import { Chart as ChartJS, ArcElement, LineElement, CategoryScale, LinearScale, PointElement, Legend, Tooltip, LogarithmicScale, RadialLinearScale, Filler } from 'chart.js';
ChartJS.register(
  ArcElement,
  LineElement,
  CategoryScale,
  LogarithmicScale,
  RadialLinearScale,
  Filler,
  LinearScale,
  PointElement,
  Legend,
  Tooltip,
)
dayjs.locale('fr')


class App extends Component<any, any> {

  constructor(props) {
    super(props);

    this.state = {
      init: false,
      minimize: false
    }
  }

  async componentDidMount() {
    if (AuthService.hasToken()) {
      OptionService.load();
      OptionService.setPrestation((await EntityManager.all<Prestation>(Prestation)).models);
    }
    this.setState({init: true});
  }

  render() {
    const {theme} = this.props
    if (!this.state.init) return <></>
    document.body.className = theme
    return (
      <div className={classNames({"base-app": true, "minimize": this.state.minimize})}>
        <Navbar minimize={this.state.minimize} onMinimize={() => this.setState({minimize: !this.state.minimize})} />
        <Switch>
          <PrivateRoute exact path="/yards/:id" component={(p) => <YardShowPage id={p.match.params.id} />}/>
          <PrivateRoute exact path="/users/:id" component={(p) => <UserShowPage id={p.match.params.id} />}/>
          {AdminRouter(sessionBloc.user).map(route => (
            <PrivateRoute key={route.label} exact={route.exact} path={route.url} component={() => {
              return <route.page/>
            }} />
          ))}
        </Switch>
      </div>
    );
  }
}

export default consumeSessionBloc(App);
