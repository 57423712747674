import SmartSelect from '@components/input/SmartSelect';
import * as React from 'react';
import Yard from 'src/models/Yard';
import { YardCompositionProgress } from './YardCompositionProgress';

export interface IYardProgressProps {
  yard: Yard
  className?: string
}

export default class YardProgress extends React.PureComponent<IYardProgressProps, any> {

  public doneDateOptions;

  constructor(props) {
    super(props);
    this.doneDateOptions = [{label: "Tout", id: "all"}, ...props.yard.getMonthReported()]
    this.state = {
      selected: this.doneDateOptions[0]
    }
  }

  onChange = (selected) => this.setState({selected})

  public render() {
    const {className, yard} = this.props;
    const {selected} = this.state;
    return (
      <div className={className}>
        <div className="row-end mb-4">
          <SmartSelect noSearch onChange={this.onChange} value={selected} options={this.doneDateOptions} />
        </div>
        {yard.yardCompositions.map((compo, index) => <YardCompositionProgress selected={selected.id} key={"a" +index} compo={compo} />)}
      </div>
    );
  }
}
